import { configure, defineRule } from "vee-validate";
import { required, email, min, max } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import { parse as parseDate, isValid as isValidDate } from "date-fns";
import {
  ageMaxRule,
  ageMinRule,
  dateRule,
  latinStringRule,
  phoneNumberRule,
} from "@team-uep/n-inputs";
import { useRoute } from "vue-router";
import { getCurrentDecli } from "@/helpers/declis";

import { codes } from "@/codes";
import messages from "../translations.base.csv";
import opConfig from "./config/opConfig";

const route = useRoute();
const decli = getCurrentDecli(route);

type ValidatorFunction = (value: unknown, params?: unknown) => boolean;

/**
 * Custom vee-validate rule to handle email already registered error message.
 * This rule should as following this small example:
 *
 * ```typescript
 *  // Update your ref after a post-register, which should contain a specific
 *  // error message when the entered user email is already user.
 *  const isEmailAlreadyRegistered = ref(false);
 *  const emailRule = computed(() => `email|emailAlreadyRegistered:${isEmailAlreadyRegistered.value}`);
 * ```
 *
 * @param value vee-validate input value (user entered email).
 * @param params value after the rule name `:`. Should contain a boolean in the
 * form of a string.
 * @returns a boolean, which indicate if the rule is passed or not.
 */
const emailAlreadyRegisteredRule = (value: string, params: [string]): boolean => {
  return params[0] === "false";
};

const radioRequiredRule = (value: number): boolean => value === 2 || value === 1;

/**
 * Verify if the leaflet code entered by the user is registered inside `codes.ts`.
 *
 * @param value Input value
 * @returns True if the code is referenced.
 */
const leaflet = (value: string): boolean => codes.indexOf(value) > -1;

/**
 * Custom birthdate rule that is optional when there is no value. Custom date
 * parsing to validate a date and verify min/max age.
 *
 * @param value Vee-validate birthdate value with mask.
 * @returns A string if invalid, `true` if valid.
 */
const birthdate = (value: string): boolean | string => {
  // Make it optional if empty.
  if (value.length === 0) {
    return true;
  }

  // Wait for all input characters entered before validating year age.
  if (value.length < 10) {
    return "Le champ date de naissance est invalide.";
  }

  const parsedDate = parseDate(value, "dd/MM/yyyy", new Date());

  // Verify if date is valid (avoid 32/50/0000 for example).
  if (!isValidDate(parsedDate)) {
    return "Le champ date de naissance est invalide.";
  }

  // Max age is 100 years
  if (parsedDate.getFullYear() < new Date().getFullYear() - 100) {
    return "Le champ date de naissance est invalide.";
  }

  // Min age is 18 years.
  if (parsedDate.getFullYear() > new Date().getFullYear() - 18) {
    return "Vous devez avoir plus de 18 ans pour participer.";
  }

  return true;
};

defineRule("required", required);
defineRule("quizRequired", required);
defineRule("radioRequired", radioRequiredRule);
defineRule("email", email);
defineRule("emailAlreadyRegistered", emailAlreadyRegisteredRule);
defineRule("min", min);
defineRule("max", max);
defineRule("isPhoneNumberValid", phoneNumberRule as ValidatorFunction);
defineRule("ageMax", ageMaxRule as ValidatorFunction);
defineRule("ageMin", ageMinRule as ValidatorFunction);
defineRule("date", dateRule as ValidatorFunction);
defineRule("latinString", latinStringRule as ValidatorFunction);
defineRule("leaflet", leaflet);
defineRule("birthdate", birthdate);

// Disbling the following ESLint rules until we can get a properly typed translation file.
/*
  eslint
    @typescript-eslint/no-unsafe-assignment: "off",
    @typescript-eslint/no-unsafe-call: "off",
    @typescript-eslint/no-unsafe-member-access: "off"
*/
const errorTraductions = opConfig.declis.reduce(
  (object, currDecli) => ({
    ...object,
    [currDecli.name]: {
      messages: {
        required: messages[currDecli.name].errors.required,
        radioRequired: messages[currDecli.name].errors.required,
        email: messages[currDecli.name].errors.email.invalid,
        emailAlreadyRegistered: messages[currDecli.name].errors.email.alreadyRegistered,
        ageMax: messages[currDecli.name].errors.birthday.tooOld,
        ageMin: messages[currDecli.name].errors.birthday.tooYoung,
        date: messages[currDecli.name].errors.birthday.invalid,
        latinString: messages[currDecli.name].errors.invalid,
        isPhoneNumberValid: messages[currDecli.name].errors.invalid,
        min: messages[currDecli.name].errors.invalid,
        max: messages[currDecli.name].errors.invalid,
        quizRequired: messages[currDecli.name].errors.answer.required,
        leaflet: "Le code de participation n'est pas valide.",
        "phone-number": "Le champ numéro de téléphone n'est pas valide.",
      },

      names: {
        civility: messages[currDecli.name].register.form.gender.toLowerCase(),
        firstname: messages[currDecli.name].register.form.firstname.toLowerCase(),
        lastname: messages[currDecli.name].register.form.lastname.toLowerCase(),
        email: messages[currDecli.name].register.form.email.toLowerCase(),
        birthdate: messages[currDecli.name].register.form.birthday.toLowerCase(),
        phone: messages[currDecli.name].register.form.mobile.toLowerCase(),
        zipcode: messages[currDecli.name].register.form.zipcode.toLowerCase(),
        leaflet: "code de participation",
        terms: messages[currDecli.name].register.form.terms.toLowerCase(),
        phonenumber: "numéro de téléphone",
      },
    },
  }),
  {}
);

configure({
  generateMessage: localize(errorTraductions),
});

setLocale(decli.name);
