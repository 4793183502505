import { RouteRecordRaw } from "vue-router";

import { RouteMeta } from "@/types/routes.interfaces";
import doorman from "@/navigation-guard/doorman";
import Register from "@/views/Register.vue";
import Diffusion from "@/views/Diffusion.vue";
import End from "@/views/End.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: "registerFr",
    path: "/",
    alias: "/fr",
    component: Register,
    meta: {
      pageId: 1,
      register: true,
      nextPage: "confirmFr",
    } as RouteMeta,
    beforeEnter: doorman().checkRelog().isLive({ name: "endFr" }).checkPageView().start(),
  },
  {
    name: "confirmFr",
    path: "/confirmation",
    component: Diffusion,
    meta: {
      pageId: 2,
    } as RouteMeta,
    beforeEnter: doorman()
      .checkRelog()
      .isAuth({ name: "registerFr" })
      .checkPageView()
      .checkSession()
      .start(),
  },
  {
    name: "endFr",
    path: "/fin",
    component: End,
    meta: {
      pageId: 3,
    } as RouteMeta,
    beforeEnter: doorman().checkPageView().start(),
  },
];
export default routes;
