<template>
  <div class="pt-4">
    <header>
      <h3 class="text-xl text-primary" v-html="$t('modals.shareLink.title')"></h3>
      <p class="text-gray-dark pb-1 text-xs" v-html="$t('modals.shareLink.subtitle')"></p>
    </header>
    <div>
      <NCopy
        :main-color="ui.colors.primary"
        class="w-full"
        data-cy="share-url"
        :value="shareURL"
        :message-checked="$t('modals.shareLink.confirm')"
        @copy="handleTrackClick()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import NCopy from "@team-uep/n-copy";
import { useTrackClickUrlCopy } from "@/composables/useTrackClicks";
import { useUrlCopyShareURL } from "@/composables/useShares";
import useUI from "@/composables/useUI";

const shareURL = useUrlCopyShareURL();
const handleTrackClick = useTrackClickUrlCopy();
const ui = useUI();
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
