<template>
  <Form
    ref="form"
    v-slot="{ handleSubmit }"
    class="mx-auto max-w-[320px]"
    as="form"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    @submit="null"
  >
    <header>
      <h3 class="text-xl text-primary">{{ $t("modals.shareEmail.title") }}</h3>
      <p class="text-gray-dark pb-1 text-xs">
        {{ $t("modals.shareEmail.subtitle") }}
      </p>
    </header>
    <div class="modal_body">
      <div v-for="index of [1, 2, 3]" :key="index">
        <n-text
          :id="'share-email-' + index"
          :data-cy="'share-email-input-' + index"
          :name="'email' + index"
          type="email"
          label-position="side"
          error-trigger="blur"
          :design-type="ui.panoply.inputs.designType"
          :border-color="ui.colors.primary"
          :error-color="ui.colors.error"
          :placeholder="$t('register.form.email')"
          autocomplete="one-time-code"
        >
          {{ $t("modals.shareEmail.email", { index: index }) }}
        </n-text>
      </div>

      <p class="pb-3">
        <small class="text-xs text-primary">
          {{ $t("modals.shareEmail.mentions") }}
        </small>
      </p>

      <p v-show="globalError" class="text-center text-sm text-error">
        {{ globalError }}
      </p>

      <div v-show="globalSuccess" class="text-center text-primary">
        {{ globalSuccess }}
      </div>
      <div class="text-center">
        <n-cta
          class="send-button"
          data-cy="share-mail-submit"
          type="button"
          :border-radius="ui.panoply.cta.rounded ? '30px' : '0px'"
          :background-color="ui.colors.primary"
          :disabled="isLoading"
          @click="handleSubmit(submit)"
        >
          {{ $t("modals.shareEmail.submit") }}
          <template v-if="isLoading" #icon>
            <n-icon-loading :color="ui.colors.primary" />
          </template>
        </n-cta>
      </div>
    </div>
  </Form>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Form } from "vee-validate";
import { NText } from "@team-uep/n-inputs";
import { useI18n } from "vue-i18n";
import { useApiOp } from "@team-uep/vue-api-op";
import NCta from "@team-uep/n-cta";
import { NIconLoading } from "@team-uep/n-icon";
import { useUserStore } from "@/store/user";
import useUI from "@/composables/useUI";
import { paths } from "@/types/api.interfaces";

type Emails = {
  email1: string;
  email2: string;
  email3: string;
};

type ApiReturn = { [email: string]: { code: number } };

function emailObjToArray(emails: Emails): string[] {
  return Object.values(emails)
    .filter((email) => email)
    .map((email) => email);
}

const apiOp = useApiOp();
const { t } = useI18n();
const isLoading = ref(false);

const ui = useUI();

const globalSuccess = ref<false | string>(false);
const globalError = ref<false | string>(false);
const userStore = useUserStore();

const validationSchema = ref({
  email1: `email`,
  email2: `email`,
  email3: `email`,
});

const initialValues = ref<Emails>({
  email1: "",
  email2: "",
  email3: "",
});

const checkEmailsValidity = (emailList: string[]) => {
  if (emailList.length === 0) {
    globalError.value = t("errors.email.noEmail");
    return false;
  }

  if (new Set(emailList).size !== emailList.length) {
    globalError.value = t("errors.email.duplicateEmail");
    return false;
  }

  if (userStore.$state.email && emailList.includes(userStore.$state.email)) {
    globalError.value = t("errors.email.ownEmail");
    return false;
  }

  return true;
};

const displaySuccessErrors = (
  values: Emails,
  apiReturn: ApiReturn,
  {
    setFieldValue,
    setFieldError,
  }: {
    setFieldValue: (field: string, value: unknown) => void;
    setFieldError: (field: string, message: string | undefined) => void;
  }
) => {
  Object.keys(apiReturn).forEach((email) => {
    const success = apiReturn[email].code === 200;
    const key = Object.keys(values).find(
      (emailKey) => values[emailKey as "email1" | "email2" | "email3"] === email
    );
    if (key) {
      if (success) {
        setFieldValue(key, "");
      } else {
        setFieldError(key, t("errors.email.alreadyUsed"));
      }
    } else {
      throw new Error(`Email ${email} not found in the list ${JSON.stringify(values)}`);
    }
  });

  // If more than one good email is sent
  const successCount = Object.values(apiReturn).filter(({ code }) => code === 200).length;
  if (successCount > 0) {
    globalSuccess.value =
      successCount > 1 ? t("modals.shareEmail.success", 2) : t("modals.shareEmail.success", 1);
  }
};

const sendVirals = async (emails: string[]): Promise<ApiReturn> => {
  const { data } = await apiOp.diffusionSendVirals<
    paths["/diffusion/send_virals"]["post"]["responses"]["200"]["content"]["application/json"],
    paths["/diffusion/send_virals"]["post"]["requestBody"]["content"]["application/json"]
  >({
    emails,
  });
  const apiReturn = data.data[0] as ApiReturn;
  return apiReturn;
};

const submit = async (
  values: Emails,
  {
    setFieldValue,
    setFieldError,
  }: {
    setFieldValue: (field: string, value: unknown) => void;
    setFieldError: (field: string, message: string | undefined) => void;
    setErrors: (fields: Partial<Record<string, string | undefined>>) => void;
    setValues: (fields: Partial<Record<string, unknown>>) => void;
    setFieldTouched: (field: string, isTouched: boolean) => void;
    setTouched: (fields: Partial<Record<string, boolean>>) => void;
    // resetForm: (state?: Partial<FormState>) => void;
  }
) => {
  const emails = emailObjToArray(values);
  const isEmailsValid = checkEmailsValidity(emails);

  if (isEmailsValid) {
    isLoading.value = true;
    try {
      const apiReturn = await sendVirals(emails);
      displaySuccessErrors(values, apiReturn, { setFieldValue, setFieldError });
    } catch (error) {
      globalError.value = t("errors.api", { error: (error as { message: string }).message });
    }
    isLoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
