import opConfig from "@/config/opConfig";

function escapePoints(string: string): string {
  return string.split(".").join("\\.");
}

function normalizeHostname(url: string): string {
  if (url.indexOf("http") === 0) {
    return new URL(url).hostname;
  }
  return url;
}

/**
 * Check app running in development by detecting `localhost` or dev URL keyword in the URL.
 *
 * @param {string} [hostname=window.location.hostname]
 * @returns {boolean}
 */
export function isDevEnv(hostname = window.location.hostname): boolean {
  const urls = [
    "localhost",
    ".localhost",
    ".numberly.dev",
    ...opConfig.declis.map(({ devURL }) => escapePoints(normalizeHostname(devURL))),
  ];
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return normalizeHostname(hostname).search(regexp) > -1;
}

/**
 * Detect app running in staging by detecting `numberly.st` keyword in the URL.
 *
 * @param {string} [hostname=window.location.hostname]
 * @returns {boolean}
 */
export function isStagingEnv(hostname = window.location.hostname): boolean {
  const urls = [
    ".numberly.st",
    ...opConfig.declis.map(({ stagingURL }) => escapePoints(normalizeHostname(stagingURL))),
  ];
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return hostname.search(regexp) > -1;
}

/**
 * Detect app running in production by checking if not staging or development environment.
 *
 * @param {string} [hostname=window.location.hostname]
 * @returns {boolean}
 */
export function isProdEnv(hostname = window.location.hostname): boolean {
  return !isDevEnv(hostname) && !isStagingEnv(hostname);
}

/**
 * Detect app running in debug mode.
 * To activate debug mode add param debug=1 to query string.
 *
 * @returns {boolean}
 */
export function isDebugMode(): boolean {
  return /debug=1/gi.test(window.location.search);
}

/**
 * Is in iframe context.
 *
 * @returns {boolean}
 */
export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (error) {
    return true;
  }
}

/**
 * Is on tablet device
 *
 * @returns {boolean}
 */
export function isTabletDevice(): boolean {
  const { userAgent } = navigator;
  return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
}

/**
 * Is on mobile device
 *
 * @returns {boolean}
 */
export function isMobileDevice(): boolean {
  const { userAgent } = navigator;
  return (
    !isTabletDevice() &&
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  );
}

/**
 * Is on desktop device
 *
 * @returns {boolean}
 */
export function isDesktopDevice(): boolean {
  return !isTabletDevice() && !isMobileDevice();
}

/**
 * Is in Facebook webview
 *
 * @returns {boolean}
 */
export function isFacebookBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("fban") > -1 || userAgent.indexOf("fbav") > -1;
}

/**
 * Is in Instagram webview
 *
 * @returns {boolean}
 */
export function isInstagramBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("instagram") > -1;
}

/**
 * Is in Twitter webview
 *
 * @returns {boolean}
 */
export function isTwitterBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("twitter") > -1;
}

/**
 * Is in Snapchat webview
 *
 * @returns {boolean}
 */
export function isSnapchatBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("snapchat") > -1;
}

/**
 * Is in Linkedin webview
 *
 * @returns {boolean}
 */
export function isLinkedinBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("linkedin") > -1;
}

/**
 * Is in webview
 *
 * @returns {boolean}
 */
export function isWebViewBrowser(): boolean {
  return (
    isFacebookBrowser() ||
    isInstagramBrowser() ||
    isSnapchatBrowser() ||
    isTwitterBrowser() ||
    isLinkedinBrowser()
  );
}

/**
 * Detect iOS
 *
 * @returns {boolean}
 */
export function isIOS(): boolean {
  const list = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
  return (
    list.includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

/**
 * Detect Android OS
 *
 * @returns {boolean}
 */
export function isAndroid(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("android") > -1;
}
