import routesFr from "@/declis/fr/routes";

export const decliConfig = {
  mainDecli: "fr",
  multiDecliOnSameURL: false,
};

export default [
  {
    decliName: "fr",
    routes: routesFr,
  },
];
