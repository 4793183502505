<template>
  <div class="terms" v-html="termsContent"></div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import termsContentFr from "@/docs/terms/test-pdf-fr.dirt.html";
import termsContentEn from "@/docs/terms/test-pdf-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";

const emit = defineEmits<{
  (e: "updateSize", values: string): void;
}>();

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const termsContent = computed(() =>
  getCurrentDecliName(route) === "fr" ? termsContentFr : termsContentEn
);
</script>

<style lang="scss" scoped>
.terms {
  :deep(h1),
  :deep(h2),
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    margin: 0em 0em 0.25em 0em;
  }

  :deep(h1) {
    @apply text-center text-xl font-bold;
  }
  :deep(h2) {
    @apply text-lg font-bold;
  }
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    @apply text-md;
  }
  :deep(p),
  :deep(ul),
  :deep(li) {
    @apply text-xs;
    margin: 0.25em 0em 1em 0em;
  }

  :deep(a) {
    @apply text-primary;

    &:hover {
      @apply underline;
    }
  }
}
</style>
