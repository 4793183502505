<template>
  <div>
    <!-- vuepress@iubenda-get-privacy-policy-url -->
    <iframe
      class="cookieModal"
      :src="iubenda.getPrivacyPolicyURL()"
      scrolling="yes"
      frameborder="0"
      allowtransparency="true"
      title="iubenda"
    ></iframe>
    <!-- vuepress@iubenda-get-privacy-policy-url -->
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";

const emit = defineEmits<{
  (e: "updateSize", values: string): void;
}>();

onMounted(() => {
  emit("updateSize", "max");
});

const iubenda = inject("iubenda") as Iubenda;
</script>

<style lang="scss" scoped>
.cookieModal {
  width: calc(100% + 50px);
  height: 90vh;
  margin: -25px 0 0 -25px;
}
</style>
