<template>
  <div class="relative flex w-full items-center lg:px-3">
    <button
      type="button"
      class="hidden h-[28px] w-[28px] items-center justify-center rounded-full bg-primary text-white transition-opacity duration-300 ease-in-out lg:inline-flex"
      :class="[swiperInstance && swiperInstance.isBeginning ? 'opacity-50' : '']"
      @click="swiperInstance ? swiperInstance.slidePrev() : null"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </button>

    <Swiper
      slides-per-view="auto"
      :space-between="24"
      :breakpoints="{
        1024: {
          spaceBetween: 24,
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      }"
      @swiper="onSwiper"
    >
      <SwiperSlide
        v-for="(carouselProduct, i) in products"
        :key="`product-${i}`"
        class="mb-2 flex !h-[180px] cursor-pointer flex-col rounded-3xl"
      >
        <button
          type="button"
          class="h-full rounded-[24px] bg-white py-4"
          @click="exitUrl(carouselProduct.linkNumber)"
        >
          <div class="mb-4 flex h-[90px] w-full items-center justify-center">
            <img
              :src="carouselProduct.image"
              :alt="carouselProduct.name"
              class="mx-auto block h-auto w-[80px]"
            />
          </div>

          <div class="flex h-[40px] w-full items-center justify-center">
            <p class="mx-auto max-w-[100px] text-center text-[14px] font-bold text-black">
              {{ carouselProduct.name }}
            </p>
          </div>
        </button>
      </SwiperSlide>
    </Swiper>

    <button
      type="button"
      class="hidden h-[28px] w-[28px] items-center justify-center rounded-full bg-primary text-white transition-opacity duration-300 ease-in-out lg:inline-flex"
      :class="[swiperInstance && swiperInstance.isEnd ? 'opacity-50' : '']"
      @click="swiperInstance ? swiperInstance.slideNext() : null"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Swiper as SwiperInstance } from "swiper";
import { ref } from "vue";
// eslint-disable-next-line import/extensions, import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/vue";
import { useI18n } from "vue-i18n";

import { getProducts } from "@/products";
import { useExitUrl } from "@/composables/useShares";

const { t } = useI18n();
const exitUrl = useExitUrl();
const products = getProducts(t);

const swiperInstance = ref<SwiperInstance | null>(null);

const onSwiper = (swiper: SwiperInstance) => {
  swiperInstance.value = swiper;
};
</script>

<style lang="scss" scoped>
.swiper {
  @media (min-width: 1024px) {
    width: 480px;
  }
}

.swiper .swiper-wrapper .swiper-slide {
  width: 140px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

  &:first-of-type {
    margin-left: 2rem;

    @media (min-width: 1024px) {
      margin-left: unset;
    }
  }

  @media (min-width: 1024px) {
    width: unset;
  }
}
</style>
