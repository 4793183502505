/**
 * Promisify setTimeout
 */
const timeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

/**
 * Envelop a LazyRouteComponent to catch and retry load if first load failed.
 * Error example: "Loading CSS chunk 156 failed"
 * Credits: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 */
const lazyComponentLoader = <RouteComponent>(
  lazyRouteComponent: () => Promise<RouteComponent>,
  { iteration = 1, delay = 500 } = {}
): (() => Promise<RouteComponent>) => {
  return async (): Promise<RouteComponent> => {
    try {
      const routeComponent = await lazyRouteComponent();
      return routeComponent;
    } catch (err) {
      if (iteration < 1) {
        throw err;
      }

      await timeout(delay);

      return lazyComponentLoader(lazyRouteComponent, { iteration: iteration - 1, delay })();
    }
  };
};

export default lazyComponentLoader;
