import type { ComposerTranslation } from "vue-i18n";

import ControlLineInox17 from "@/assets/products/control-line-inox-1-7.png";
import ControlLineInox2 from "@/assets/products/control-line-inox-2.png";
import Gaufrier from "@/assets/products/gaufrier.png";
import KitNettoyage from "@/assets/products/kit-nettoyage.png";
import TravelMug from "@/assets/products/travel-mug.png";
import WaffleMaker from "@/assets/products/waffle-maker.png";

export type Product = {
  name: string;
  image: string;
  linkNumber: number;
};

export const getProducts = (t: ComposerTranslation): Product[] => [
  {
    name: t("diffusion.products.bouilloire"),
    image: ControlLineInox17,
    linkNumber: 5,
  },
  {
    name: t("diffusion.products.grillePain"),
    image: ControlLineInox2,
    linkNumber: 6,
  },
  {
    name: t("diffusion.products.gaufrier"),
    image: Gaufrier,
    linkNumber: 7,
  },
  {
    name: t("diffusion.products.waffleMaker"),
    image: WaffleMaker,
    linkNumber: 8,
  },
  {
    name: t("diffusion.products.kitNettoyage"),
    image: KitNettoyage,
    linkNumber: 9,
  },
  {
    name: t("diffusion.products.travelMug"),
    image: TravelMug,
    linkNumber: 10,
  },
];
