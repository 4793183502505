import { APISessionCurrentResponse } from "@/types/api-extended.interfaces";
import { Civility, UserStoreType, TrackingStoreType } from "@/types/store.interfaces";

/**
 * Update user and tracking stores after a `/session/current` endpoint.
 *
 * @param data API response data from `/session/current` endpoint.
 * @param userStore Provided user-store.
 * @param trackingStore Provided tracking store.
 */
// eslint-disable-next-line import/prefer-default-export
export const updateStoreAfterSessionCurrent = (
  data: APISessionCurrentResponse,
  userStore: UserStoreType,
  trackingStore: TrackingStoreType
): void => {
  userStore.$patch({
    id: Number(data.data[0].idU),
    civility: Number(data.data[0].id_Civilite) as Civility,
    email: data.data[0].Email,
    firstname: data.data[0].Prenom,
    lastname: data.data[0].Nom,
    phonenumber: data.data[0].telmobile,
    codeLot: data.data[0].lot.codeLot,
    birthdate:
      typeof data.data[0].oResponses.dQuestion_1 === "string"
        ? data.data[0].oResponses.dQuestion_1
        : null,
    optins: {
      emailMobileOp: data.data[0].oOptins.iOptin_1,
      emailBrand: data.data[0].oOptins.iOptin_2,
      emailPartners: data.data[0].oOptins.iOptin_3,
      mobileBrand: data.data[0].oOptins.iOptin_4,
    },
    responses: {
      question4: Number(data.data[0].oResponses.iQuestion_4) || null,
      question5: Number(data.data[0].oResponses.iQuestion_5) || null,
    },
  });

  trackingStore.$patch({
    idfrom: data.data[0].id_From,
    uid: data.data[0].uid,
    iduEmail: data.data[0].idu_email,
  });
};
