<template>
  <div class="contact" v-html="contactContent"></div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import contactContentFr from "@/docs/contact/webmail-fr.dirt.html";
import contactContentEn from "@/docs/contact/webmail-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";

const emit = defineEmits<{
  (e: "updateSize", values: string): void;
}>();

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const { t } = useI18n();
const contactContent = computed(() => {
  let htmlText = getCurrentDecliName(route) === "fr" ? contactContentFr : contactContentEn;

  htmlText = htmlText.replace(
    /{{footerContactEmail}}/g,
    `<a href="mailto:${t("footer.contact.email")}?subject=${t("footer.contact.subject")}">${t(
      "footer.contact.email"
    )}</a>`
  );
  htmlText = htmlText.replace(/{{footerContactClient}}/g, t("footer.contact.client"));

  return htmlText;
});
</script>

<style lang="scss" scoped>
.contact {
  :deep(h1),
  :deep(h2),
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    margin: 0em 0em 0.25em 0em;
  }

  :deep(h1) {
    @apply text-center text-xl font-bold;
  }
  :deep(h2) {
    @apply text-lg font-bold;
  }
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    @apply text-md;
  }
  :deep(p),
  :deep(ul),
  :deep(li) {
    @apply text-xs;
    margin: 0.25em 0em 1em 0em;
  }

  :deep(a) {
    @apply text-primary;

    &:hover {
      @apply underline;
    }
  }
}
</style>
