import { LocationQuery, useRoute } from "vue-router";

import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";
import { Civility, TrackingStoreType, UserStoreType } from "@/types/store.interfaces";
import { TrackingTypes } from "@/config/opConfig";

import { getCurrentDecli } from "../helpers/declis";

/**
 * Save query params to tracking store
 *
 * @param store tracking store
 * @param query query params
 */
export function saveQueryToTrackingStore(store: TrackingStoreType, query: LocationQuery): void {
  if (query.idfrom !== undefined) {
    store.$patch({ idfrom: Number(query.idfrom) });
  } else {
    const currentDecli = getCurrentDecli(useRoute());
    const untaggedIdfrom = currentDecli.froms.filter(
      (elt) => elt.type === TrackingTypes.Untagged
    )[0].idFrom;

    store.$patch({ idfrom: untaggedIdfrom || 0 });
  }

  if (query.idup !== undefined) {
    store.$patch({ idup: String(query.idup) });
  }
  if (query.sUid !== undefined) {
    store.$patch({ uid: String(query.sUid) });
  }
}

/**
 * Save query params to user store
 *
 * @param store user store
 * @param query query params
 */
export function saveQueryToUserStore(store: UserStoreType, query: LocationQuery): void {
  if (query.e !== undefined) {
    store.$patch({ email: String(query.e) });
  }
  if (query.p !== undefined) {
    store.$patch({ firstname: String(query.p) });
  }
  if (query.n !== undefined) {
    store.$patch({ lastname: String(query.n) });
  }

  if (query.c === "1") {
    store.$patch({ civility: Civility.Male });
  } else if (query.c === "2") {
    store.$patch({ civility: Civility.Female });
  }
}

/**
 * Save all query params to store.
 * Call it in the main component.
 */
export default (query: LocationQuery): void => {
  const trackingStore = useTrackingStore();
  const userStore = useUserStore();
  saveQueryToTrackingStore(trackingStore, query);
  saveQueryToUserStore(userStore, query);
};
