import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NModal = _resolveComponent("NModal")!

  return (_openBlock(), _createBlock(_component_NModal, {
    id: "modal-wrapper",
    ref: "modal",
    size: _ctx.initialValues.size,
    visible: _ctx.displayModal,
    class: _normalizeClass(''),
    onClose: _cache[0] || (_cache[0] = 
      () => {
        _ctx.close();
      }
    )
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.currentModal}Modal`), {
        id: `${_ctx.currentModal}Modal`,
        ref: _ctx.childrenRef,
        "data-cy": "modal-content",
        onUpdateSize: _ctx.updateSize
      }, null, 40, ["id", "onUpdateSize"]))
    ]),
    _: 1
  }, 8, ["size", "visible"]))
}