import { RouteRecordRaw } from "vue-router";
import globalSharedData from "@/composables/globalSharedData";

/**
 * Get route by decli name and page ID
 */
// eslint-disable-next-line import/prefer-default-export
export function getRouteByDecliNamePageId(
  decliName: string,
  pageId: number
): RouteRecordRaw | null {
  const { routes: allRoutes } = globalSharedData;
  const recursiveSearch = (routes: RouteRecordRaw[]): RouteRecordRaw | null => {
    for (let i = 0; i < routes.length; i += 1) {
      const route = routes[i];

      if (route.meta?.decliName === decliName && route.meta?.pageId === pageId) {
        return route;
      }
      if (route.children && route.children.length > 0) {
        const found = recursiveSearch(route.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };
  return recursiveSearch(allRoutes);
}
