import { RouteRecordRaw } from "vue-router";
import doorman from "@/navigation-guard/doorman";
import lazyComponentLoader from "@/helpers/lazyComponentLoader";

const routes: Array<RouteRecordRaw> = [
  {
    name: "ErrorPageFr",
    path: "/erreur",
    // component: ErrorPage,
    component: lazyComponentLoader(
      () => import(/* webpackChunkName: "home" */ "@/views/global/Error.vue")
    ),
    meta: {
      decliName: "fr",
    },
  },
  {
    name: "OptinisationPageFr",
    path: "/optinisation",
    // component: OptinisationPage,
    // beforeEnter: AppInit,
    component: lazyComponentLoader(
      () => import(/* webpackChunkName: "home" */ "@/views/global/Optinisation.vue")
    ),
    meta: {
      decliName: "fr",
    },
    beforeEnter: doorman().checkRelog({ redirect: false }).isAuth({ name: "registerFr" }).start(),
  },
  {
    name: "Error",
    path: "/:catchAll(.*)",
    component: lazyComponentLoader(
      () => import(/* webpackChunkName: "error" */ "@/views/global/Error.vue")
    ),
  },
];

export default routes;
