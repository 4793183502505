import { IubendaCsConfiguration } from "@team-uep/vue-iubenda-op";
import opConfigRaw from "../../op.config.json";

export const enum TrackingTypes {
  Untagged = "untagged",
  Facebook = "facebook",
  Twitter = "twitter",
  WhatsApp = "whatsapp",
  Messenger = "messenger",
  UrlCopy = "urlcopy",
  Native = "native",
}

export const enum QuestionFormat {
  Date = 1,
  Integer = 2,
  String = 3,
}

export const enum QuestionType {
  Radio = "radio",
  Select = "select",
  Checkbox = "checkbox",
  Datepicker = "datepicker",
  Text = "text",
}

export type GlobalOpConfig = {
  clientName: string;
  opName: string;
};

export type From = {
  idFrom: number;
  type: TrackingTypes;
};

export type Tracking = {
  linkNumber: number;
  type: TrackingTypes;
};

export type Response = {
  id: number;
  pageId: number;
  questionId: number;
};

export type Question = {
  id: number;
  pageId: number;
  responseFormat: QuestionFormat;
  responseUiType: QuestionType;
  responses: Response[];
};

export type Decli = {
  decliID: number;
  name: string;
  optinType: string;
  optinDisplayType: string;
  emailDedup: boolean;
  mobileDedup: boolean;
  brandOptin: boolean;
  partnerOptin: boolean;
  mobileOptin: boolean;
  langTag: string;
  prodURL: string;
  stagingURL: string;
  devURL: string;
  iubendaCsConfiguration: IubendaCsConfiguration;
  recaptchaSiteKeyStaging: string;
  recaptchaSiteKeyProd: string;
  froms: From[];
  trackings: Tracking[];
};

export type ConfigOp = {
  global: GlobalOpConfig;
  declis: Decli[];
  questions: Question[];
};

const opConfig = opConfigRaw as unknown as ConfigOp;

export default opConfig;
