import { computed, ComputedRef, ref, watch, Ref } from "vue";
import { FormContext } from "vee-validate";
import { useUserStore } from "@/store/user";
import type { NPhoneEvent } from "@/types/n-inputs.interfaces";

export const enum RegisterField {
  Leaflet = "leaflet",
  Civility = "civility",
  Firstname = "firstname",
  Lastname = "lastname",
  Email = "email",
  Zipcode = "zipcode",
  Birthdate = "birthdate",
  Mobile = "mobile",
  EmailOptin = "emailOptin",
  SmsOptin = "smsOptin",
  Terms = "terms",
}

export type RegisterTypes = {
  [RegisterField.Leaflet]: string;
  [RegisterField.Civility]: number;
  [RegisterField.Firstname]: string;
  [RegisterField.Lastname]: string;
  [RegisterField.Email]: string;
  [RegisterField.Birthdate]: string;
  [RegisterField.Zipcode]: number | "";
  [RegisterField.Mobile]: string;
  [RegisterField.EmailOptin]: boolean;
  [RegisterField.SmsOptin]: boolean;
  [RegisterField.Terms]: boolean;
};

export type RegistervalidationSchema = {
  [RegisterField.Leaflet]: ComputedRef<string> | string;
  [RegisterField.Civility]: ComputedRef<string> | string;
  [RegisterField.Firstname]: ComputedRef<string> | string;
  [RegisterField.Lastname]: ComputedRef<string> | string;
  [RegisterField.Email]: ComputedRef<string> | string;
  [RegisterField.Birthdate]: ComputedRef<string> | string;
  [RegisterField.Zipcode]: ComputedRef<string> | string;
  [RegisterField.Mobile]: ComputedRef<string> | string;
  [RegisterField.EmailOptin]: ComputedRef<string> | string;
  [RegisterField.SmsOptin]: ComputedRef<string> | string;
  [RegisterField.Terms]: ComputedRef<string> | string;
};

export default (props: {
  isEmailAlreadyRegistered: boolean;
}): {
  form: Ref<FormContext<RegisterTypes> | null>;
  initialValues: Ref<RegisterTypes>;
  schema: ComputedRef<RegistervalidationSchema>;
  handlePhoneChange: (event: Event | NPhoneEvent) => void;
} => {
  const userStore = useUserStore();

  const form = ref<FormContext<RegisterTypes> | null>(
    null
  ) as Ref<FormContext<RegisterTypes> | null>;
  const isPhoneNumberValid = ref(true);

  const initialValues = ref<RegisterTypes>({
    leaflet: "",
    email: userStore.$state.email || "",
    firstname: userStore.$state.firstname || "",
    lastname: userStore.$state.lastname || "",
    civility: Number(userStore.$state.civility),
    birthdate: "",
    zipcode: "",
    mobile: "",
    emailOptin: false,
    smsOptin: false,
    terms: false,
  });

  const handlePhoneChange = (event: Event | NPhoneEvent) => {
    // Ignore default browser events that is sent sometimes by the n-phone.
    if (event instanceof Event || !form.value) {
      return;
    }

    // Make this field required, when user type a value and delete it, it should pass.
    if (event.inputNumber === "") {
      isPhoneNumberValid.value = true;
    } else {
      // `event.valid` can be `undefined`.
      isPhoneNumberValid.value = !!event.valid;
    }

    // Force validation of the "phone" field.
    form.value.validateField(RegisterField.Mobile).catch(console.log);
  };

  const schema = computed<RegistervalidationSchema>(() => ({
    leaflet: "required|leaflet",
    civility: "radioRequired",
    firstname: "required|latinString",
    lastname: "required|latinString",
    email: `required|email|emailAlreadyRegistered:${String(props.isEmailAlreadyRegistered)}`,
    birthdate: "birthdate",
    zipcode: "required|min:5|max:5",
    mobile: `required|isPhoneNumberValid:${String(isPhoneNumberValid.value)}`,
    emailOptin: "",
    smsOptin: "",
    terms: "required",
  }));

  // When `isEmailAlreadyRegistered` change from `false` to `true`, trigger
  // a form refresh because the form doesn't detect ref which changes rules.
  watch(
    () => props.isEmailAlreadyRegistered,
    (value, oldValue) => {
      if (form.value && value && !oldValue) {
        form.value.validateField(RegisterField.Email).catch(console.log);
      }
    }
  );

  return {
    form,
    initialValues,
    schema,
    handlePhoneChange,
  };
};
