<template>
  <div class="text-center">
    <p>
      <a href="https://numberly.com/fr" target="_blank">
        <img class="mx-auto block max-w-xs" :src="numberlyLogo" alt="Numberly logo" />
      </a>
    </p>

    <p v-html="$t('footer.realisation.address')"></p>

    <p>
      <a :href="$t('footer.realisation.numberly')" target="_blank">www.numberly.com</a><br />
      {{ $t("footer.realisation.contact") }}<br />
      {{ $t("footer.realisation.email") }} :
      <a
        class="text-primary"
        data-cy="mailto-contact"
        :href="`mailto:${$t('footer.realisation.mailto')}?subject=${$t('footer.contact.subject')}`"
        target="_blank"
        >{{ $t("footer.realisation.mailto") }}</a
      >
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import NumberlyInternationalLogo from "@/assets/logo/numberly-international.svg";
import NumberlyFranceLogo from "@/assets/logo/numberly-france.svg";
import { getCurrentDecliName } from "@/helpers/declis";

const numberlyInternationalLogoSrc = NumberlyInternationalLogo;
const numberlyFranceLogo = NumberlyFranceLogo;
const route = useRoute();

const numberlyLogo = computed(() =>
  getCurrentDecliName(route) === "fr" ? numberlyFranceLogo : numberlyInternationalLogoSrc
);
</script>

<style lang="scss"></style>
