<template>
  <div
    class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="relative w-[310px] rounded-md bg-white shadow-2xl">
      <img
        src="@/assets/register/leaflet-code-instruction.png"
        alt=""
        class="absolute top-0 left-1/2 -mt-[70px] block h-[140px] w-[140px] -translate-x-1/2"
      />

      <div class="flex flex-col items-center justify-center pt-20 pb-6">
        <p class="mb-6 text-center" v-html="$t('register.leafletPop.description')"></p>

        <button
          type="button"
          class="relative flex items-center justify-center rounded-[24px] bg-[#FD7412] py-3 px-10 text-center text-[14px] font-bold uppercase text-white"
          @click="emit('close')"
        >
          {{ $t("register.leafletPop.cta") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "close"): void;
}>();
</script>
