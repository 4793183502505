import { OpStatus, getActiveApiOp, ApiOpBackendEventName } from "@team-uep/vue-api-op";
import {
  NavigationFailure,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";
import {
  APISessionCurrentResponse,
  APISessionRelogResponse,
} from "@/types/api-extended.interfaces";
import { updateStoreAfterSessionCurrent } from "@/helpers/store";
import { getRouteByDecliNamePageId } from "@/helpers/routes";

type DoormanReturn = void | NavigationFailure | undefined | true; // true if no redirection otherwise

type TaskOptions = {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
};

type Task = (options: TaskOptions) => Promise<DoormanReturn>;

class Doorman {
  tasks: Task[] = [];

  /**
   * Check if the user is authenticated
   */
  isAuth(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const trackingStore = useTrackingStore();

      // If user uid not defined redirect the page
      if (!trackingStore.$state.uid) {
        return Promise.resolve(options.next(redirect));
      }

      return Promise.resolve(true);
    });
  }

  /**
   * Check the OP status and redirect if it not live
   */
  isLive(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getOpInfo();
      }

      if (apiOp.opStatus.value !== null && apiOp.opStatus.value !== OpStatus.Live) {
        return options.next(redirect);
      }

      return true;
    });
  }

  /**
   * Check the OP status and redirect if it not end
   */
  isEnded(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getOpInfo();
      }

      if (apiOp.opStatus.value !== OpStatus.Ended) {
        return options.next(redirect);
      }

      return true;
    });
  }

  /**
   * Check the OP status and redirect if it not unstarted
   */
  isUnstarted(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getOpInfo();
      }

      if (apiOp.opStatus.value !== OpStatus.Unstarted) {
        return options.next(redirect);
      }

      return true;
    });
  }

  /**
   * Relog using UID from tracking-store if needed. UID can be set from
   * the session-storage or a URL query parameter.
   *
   * We assume a user need to relog if there is no `email` property in the
   * user-store.
   *
   * @returns the Doorman instance used.
   */
  checkRelog({ redirect = true } = {}): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();
      const trackingStore = useTrackingStore();
      const userStore = useUserStore();

      // We assume a user try to relog if the UID (inserted into store from URL
      // query parameter) is defined but the email is empty.
      if (trackingStore.uid && userStore.email === null && !apiOp.token.value) {
        try {
          // Will trigger a `/session/token` before `/session/relog` because no
          // token have been generated at this time of execution.
          const { data: relogData }: { data: APISessionRelogResponse } = await apiOp.relog(
            trackingStore.uid
          );

          trackingStore.$patch({
            idfrom: relogData.data[0].fromId,
            idvisit: relogData.data[0].visitId,
            pageid: relogData.data[0].iPageId,
            mmtro: {
              tagid: relogData.data[0].oTagInfo.tagid,
            },
            uid: relogData.data[0].uid,
          });

          const { data: sessionData }: { data: APISessionCurrentResponse } =
            await apiOp.getSessionCurrent();

          updateStoreAfterSessionCurrent(sessionData, userStore, trackingStore);

          // Redirect where the user has left using the `pageid` returned from
          // relog endpoint which is stored at `trackingStore.pageid`.
          if (options.to.meta.pageId !== trackingStore.pageid && redirect) {
            const route = getRouteByDecliNamePageId(
              String(options.to.meta.decliName),
              Number(trackingStore.pageid)
            );

            if (!route) {
              throw new Error(`Page ID ${String(trackingStore.pageid)} not found for relog`);
            }

            return options.next(route);
          }
        } catch (err) {
          // Do nothing, don't log to Sentry
        }
      }

      return true;
    });
  }

  /**
   * Get current session API data
   */
  checkSession(): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const trackingStore = useTrackingStore();
      const userStore = useUserStore();
      const apiOp = getActiveApiOp();

      try {
        const { data: dataSession }: { data: APISessionCurrentResponse } =
          await apiOp.getSessionCurrent();

        updateStoreAfterSessionCurrent(dataSession, userStore, trackingStore);
      } catch (error) {
        // Do nothing, don't log to Sentry
      }

      return options.next();
    });
  }

  /**
   * Send pageview to API and redirect if route is unauthorized
   */
  checkPageView(redirect: RouteLocationRaw | boolean = false): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      const cbApiPageView = async (r: { response: Response; data: unknown }) => {
        const json = (await r.response.json()) as { data: number[] };

        const route = getRouteByDecliNamePageId(
          String(options.to.meta.decliName),
          Number(json.data[0])
        );

        if (!route) {
          throw new Error(`Page ID ${String(json.data[0])} not found for relog`);
        }
        options.next(route);

        return r;
      };

      apiOp.on(ApiOpBackendEventName.UNAUTHORIZED, cbApiPageView);

      try {
        await apiOp.pageView({ iPageId: options.to.meta.pageId });
      } catch (error) {
        if (redirect) {
          return options.next(redirect as RouteLocationRaw);
        }
      }

      apiOp.off(ApiOpBackendEventName.UNAUTHORIZED, cbApiPageView);

      return true;
    });
  }

  /**
   * Start the process
   */
  start(): (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => Promise<void> {
    return async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      const options = {
        to,
        from,
        next,
      };
      await this.runTasks([...this.tasks], options);
    };
  }

  /**
   * Start all tasks
   */
  private async runTasks(tasks: Task[], options: TaskOptions): Promise<DoormanReturn> {
    const task = tasks.shift();
    if (task) {
      // Stop tests if a route is already called
      const continueTasks = await task(options);
      if (continueTasks === true) {
        return this.runTasks(tasks, options);
      }
      return continueTasks;
    }

    return options.next();
  }

  /**
   * Insert a new task
   */
  private addTask(task: Task): Doorman {
    this.tasks.push(task);
    return this;
  }

  /**
   * Return the payload to send for the `/session/token` API endpoint.
   */
  private static getSessionTokenPayload() {
    const trackingStore = useTrackingStore();
    const urlParameters = new URLSearchParams(window.location.search);

    return {
      fromId: trackingStore.$state.idfrom || 0,
      parrainId: trackingStore.$state.idup ? parseInt(trackingStore.$state.idup, 10) : 0,
      uid: trackingStore.uid,
      ueid: trackingStore.ueId,
      urlParams: Object.fromEntries(urlParameters.entries()),
      res: `${window.screen.width}x${window.screen.height}`,
    };
  }
}

/**
 * Initialise Doorman instance
 */
const doorman = (): Doorman => {
  return new Doorman();
};

export default doorman;
