import { Store } from "pinia";

export enum Civility {
  Male = 1,
  Female = 2,
  Undefined = 0,
}

export enum OpStatus {
  Unstarted = 2,
  Live = 1,
  Ended = 0,
}

export enum OpOptinStatus {
  Optout = 0,
  Optin = 1,
}

export enum BrandOptinStatus {
  // Haven't checked the optin choice in the register form.
  Optout = 0,
  // Checked the optin choice in the register form.
  Optin = 1,
  // When there is no optin choice, you will be always optin by default with LCEN derogation.
  OptinLCEN = 2,
  // When already present in a dedup table.
  Dedup = 3,
  // Mostly used on DE declis which needs approval of optin.
  WaitingConfirmation = 6,
  // Mostly used on DE declis after clicking on the optin confirmation email.
  ConfirmedOptin = 7,
}

export enum OptinStatus {
  // Haven't checked the optin choice in the register form.
  Optout = 0,
  // Checked the optin choice in the register form.
  Optin = 1,
  // When there is no optin choice, you will be always optin by default with LCEN derogation.
  OptinLCEN = 2,
  // When already present in a dedup table.
  Dedup = 3,
}

export type Responses = {
  // Question answer, in oResponses.iQuestion_4 for example.
  question4: number | null;
  // Question answer, in oResponses.iQuestion_5 for example.
  question5: number | null;
};

export type Optin = {
  /**
   * iOptin_1 sent by the back-end.
   */
  emailMobileOp: OpOptinStatus | null;

  /**
   * iOptin_2 sent by the back-end.
   */
  emailBrand: BrandOptinStatus | null;

  /**
   * iOptin_3 sent by the back-end.
   */
  emailPartners: OptinStatus | null;

  /**
   * iOptin_4 sent by the back-end.
   */
  mobileBrand: OptinStatus | null;
};

export type UserState = {
  id: number | null; // idu from API
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  phonenumber: string | null;
  codeLot: string | null;
  civility: Civility | null;
  birthdate: string | null;
  responses: Responses;
  optins: Optin;
};

export type TrackingState = {
  idfrom: number | null;
  // ID(U) parrain.
  idup: string | null;
  uid: string | null;
  ueId: string | null;
  iduEmail: number | null;
  idvisit: number | null;
  pageid: number | null;
  mmtro: {
    tagid: string | null;
  };
};

export type UserStoreType = Store<"user", UserState>;

export type TrackingStoreType = Store<"tracking", TrackingState>;
