import { RouteLocationNormalizedLoaded, RouteRecordRaw, useRoute } from "vue-router";
import { computed, ComputedRef } from "vue";
import opConfig, { Decli } from "@/config/opConfig";
import { decliConfig } from "@/declis";

/**
 * Main decli is configured by `src/index.ts` file.
 * If you need the current decli please use `getCurrentDecli()`.
 *
 * @returns {Decli}
 */
export function getMainDecli(): Decli {
  const currentDecli = opConfig.declis.find((decli) => decli.name === decliConfig.mainDecli);

  if (currentDecli) {
    return currentDecli;
  }

  console.warn(`Main decli ${decliConfig.mainDecli} not found (src/index.ts)`);

  if (opConfig.declis.length < 1) {
    throw new Error(
      `Declis empty in the file op.config.json, fallback to first decli (${opConfig.declis[0].name})`
    );
  }

  return opConfig.declis[0];
}

/**
 * Default decli of the domain (not necessarily real decli defined by the route).
 * If you need the current decli please use `getCurrentDecli()`.
 *
 * @param {string} [hostname=window.location.hostname]
 * @returns {Decli}
 */
export function getDecliByDomain(hostname: string = window.location.hostname): Decli {
  const allDeclisByDomain: Array<{ decli: Decli; hostname: string }> = [];

  opConfig.declis.forEach((decli) => {
    allDeclisByDomain.push({
      decli,
      hostname: decli.prodURL,
    });
    allDeclisByDomain.push({
      decli,
      hostname: decli.devURL,
    });

    // Add decli sub-domains to localhost URL
    allDeclisByDomain.push({
      decli,
      hostname: decli.devURL.replace("numberly.dev", "localhost"),
    });

    allDeclisByDomain.push({
      decli,
      hostname: decli.stagingURL,
    });
  });

  // Sort by bigger domain length to avoid search errors
  allDeclisByDomain.sort((a, b) => b.hostname.length - a.hostname.length);

  const currentDecli = allDeclisByDomain.find((decli) => hostname.search(decli.hostname) > -1);
  if (!currentDecli) {
    console.warn(
      `Decli on URL ${hostname} not found, fallback to main decli (${decliConfig.mainDecli})`
    );

    return getMainDecli();
  }

  return currentDecli.decli;
}

/**
 * Decli defined by the route
 *
 * @param {RouteRecordRaw} [route=useRoute()]
 * @returns {Decli}
 */
export function getCurrentDecli(route: RouteRecordRaw | RouteLocationNormalizedLoaded): Decli {
  if (route?.meta?.decliName) {
    const currentDecli = opConfig.declis.find(
      (decli) => route.meta && decli.name === route.meta.decliName
    );
    if (currentDecli) {
      return currentDecli;
    }
  }

  console.warn(
    `Decli not found for route ${String(route?.name)} not found, fallback to domain (${
      getDecliByDomain().name
    })`
  );

  return getDecliByDomain();
}

/**
 * Name of the decli defined by the route
 *
 * @param {RouteRecordRaw} [route=useRoute()]
 * @returns {string}
 */
export function getCurrentDecliName(route: RouteRecordRaw | RouteLocationNormalizedLoaded): string {
  return getCurrentDecli(route).name;
}

/**
 * Computed of current decli
 *
 * @returns {ComputedRef<Decli>}
 */
export function useCurrentDecli(): ComputedRef<Decli> {
  const route = useRoute();
  return computed(() => getCurrentDecli(route));
}
