import { useApiOp, ApiError } from "@team-uep/vue-api-op";
import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { paths } from "@/types/api.interfaces";
import { useUserStore } from "@/store/user";
import { useCurrentDecli } from "@/helpers/declis";
import { TrackingTypes } from "@/config/opConfig";

/**
 * Get share URL
 */
export const useShareURL = (idfrom: number): ComputedRef<string> => {
  const userStore = useUserStore();
  return computed(
    () => `${window.location.origin}/?idfrom=${idfrom}&idup=${userStore.$state.id || 0}`
  );
};

/**
 * Computed of share URL from tracking type
 */
function useShareURLFromTrackinType(trackingType: TrackingTypes): ComputedRef<string> {
  const userStore = useUserStore();
  const currentDecli = useCurrentDecli();
  const idFrom = computed(
    () => currentDecli.value.froms.find(({ type }) => type === trackingType)?.idFrom || 0
  );
  return computed(
    () => `${window.location.origin}/?idfrom=${idFrom.value}&idup=${userStore.$state.id || 0}`
  );
}

/**
 * Get Facebook share URL
 */
export const useFacebookShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.Facebook);
  return computed(
    () => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL.value)}`
  );
};

/**
 * Get Native share URL
 */
export const useNativeShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.Native);
  return computed(() => shareURL.value);
};

/**
 * Get URL copy share URL
 */
export const useUrlCopyShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.UrlCopy);
  return computed(() => shareURL.value);
};

/**
 * Get Twitter share URL
 */
export const useTwitterShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.Twitter);
  const { t } = useI18n({ useScope: "global" });
  const description = t("diffusion.share.twitterDescription");
  return computed(
    () =>
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        description
      )}&url=${encodeURIComponent(shareURL.value)}`
  );
};

/**
 * Get Whats App share URL
 */
export const useWhatsappShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.WhatsApp);
  const { t } = useI18n({ useScope: "global" });
  const description = t("diffusion.share.whatsappDescription");
  return computed(
    () => `whatsapp://send?text=${description} ${encodeURIComponent(shareURL.value)}`
  );
};

/**
 * Get Messenger share URL (without appId)
 */
export const useMessengerShareURL = (): ComputedRef<string> => {
  const shareURL = useShareURLFromTrackinType(TrackingTypes.Messenger);
  const appID = "xxx"; /* appIds */
  return computed(
    () => `fb-messenger://share/?link=${encodeURIComponent(shareURL.value)}&app_id=${appID}`
  );
};

/**
 * Get Exit URL function to call
 */
export const useExitUrl = (): ((linkId: number) => Promise<void>) => {
  const apiOp = useApiOp();
  const route = useRoute();

  return async (linkId: number) => {
    // iOS safari tricks
    const windowReference = window.open();

    try {
      const response = await apiOp.trackPageClick<
        paths["/track/page_click"]["post"]["responses"]["200"]["content"]["application/json"],
        paths["/track/page_click"]["post"]["requestBody"]["content"]["application/json"]
      >({
        iPageId: Number(route.meta.pageId),
        iLinkNumber: linkId,
      });

      const url = (response.data as { data: string[] }).data[0];
      if (windowReference) {
        windowReference.location = url;
      } else {
        window.open(url, "_blank");
      }
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }
    }
  };
};
