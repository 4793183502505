<template>
  <h2
    class="mx-auto mb-4 text-center text-[24px] font-bold leading-tight text-[#707070] lg:text-[32px]"
    v-html="$t('end.title')"
  ></h2>

  <p class="mb-8 text-center lg:mx-auto lg:max-w-[250px]" v-html="$t('end.subtitle')"></p>

  <n-cta
    class="mx-auto mb-4"
    :border-radius="ui.panoply.cta.rounded ? '6px' : '0px'"
    :background-color="{ default: ui.colors.primary, hover: 'white', error: ui.colors.error }"
    :text-color="{ default: 'white', hover: ui.colors.primary }"
    :border-color="{ default: 'white', hover: ui.colors.primary }"
    @click="exitUrl(1)"
  >
    Découvrir les offres
  </n-cta>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-cycle
import { useExitUrl } from "@/composables/useShares";
import useUI from "@/composables/useUI";
import NCta from "@team-uep/n-cta";

const exitUrl = useExitUrl();
const ui = useUI();
</script>

<style lang="scss" scoped></style>
