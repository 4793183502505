import { computed } from "vue";
import { useApiOp } from "@team-uep/vue-api-op";
import { useRoute } from "vue-router";
import { useTrackingStore } from "@/store/tracking";
import { useUserStore } from "@/store/user";
import { useCurrentDecli } from "@/helpers/declis";
import { TrackingTypes } from "@/config/opConfig";

/**
 * Get tracking click function to call with custom linknumber argument
 */
export const useTrackClickCustom = (): ((linkNumber: number, pageId?: number) => Promise<void>) => {
  const apiOp = useApiOp();
  const storeUser = useUserStore();
  const route = useRoute();
  const trackingStore = useTrackingStore();

  return async (linkNumber: number, pageId?: number) => {
    await apiOp.trackPageClick({
      iIdu: storeUser.$state.id || null,
      iVisitId: trackingStore.$state.idvisit || null,
      iPageId: pageId || route.meta.pageId || null,
      iLinkNumber: linkNumber,
    });
  };
};

/**
 * Get tracking click function to call from TrackingType
 */
const useTrackClick = (trackingTypes: TrackingTypes): (() => Promise<void>) => {
  const currentDecli = useCurrentDecli();
  const trackClickCustom = useTrackClickCustom();
  const linkNumber = computed(
    () => currentDecli.value.trackings.find(({ type }) => type === trackingTypes)?.linkNumber
  );

  return async () => {
    if (linkNumber.value !== undefined) {
      await trackClickCustom(linkNumber.value);
    } else {
      throw new Error(
        `Link number from TrackingTypes: "${trackingTypes}" of declination "${currentDecli.value.name}" not found`
      );
    }
  };
};

/**
 * Get Facebook tracking click function to call
 */
export const useTrackClickFacebook = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.Facebook);
};

/**
 * Get Twitter tracking click function to call
 */
export const useTrackClickTwitter = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.Twitter);
};

/**
 * Get Url Copy tracking click function to call
 */
export const useTrackClickUrlCopy = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.UrlCopy);
};

/**
 * Get Messenger tracking click function to call
 */
export const useTrackClickMessenger = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.Messenger);
};

/**
 * Get WhatsApp tracking click function to call
 */
export const useTrackClickWhatsApp = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.WhatsApp);
};

/**
 * Get Native tracking click function to call
 */
export const useTrackClickNative = (): (() => Promise<void>) => {
  return useTrackClick(TrackingTypes.Native);
};
