<template>
  <div>
    <div class="charte" v-html="charteContent"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import charteContentFr from "@/docs/charte/test-pdf-fr.dirt.html";
import charteContentEn from "@/docs/charte/test-pdf-en.dirt.html";
import { getCurrentDecliName } from "@/helpers/declis";

const emit = defineEmits<{
  (e: "updateSize", values: string): void;
}>();

onMounted(() => {
  emit("updateSize", "max");
});

const route = useRoute();
const charteContent = computed(() =>
  getCurrentDecliName(route) === "fr" ? charteContentFr : charteContentEn
);
</script>

<style lang="scss" scoped>
// CharteFbModal should have the same style as Iubenda "Politique d'utilisation des cookies".
:deep(.charte) {
  font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif !important;

  p {
    margin: 10px 0;
    font-size: 14px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    @apply text-primary;

    &:hover {
      @apply underline;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 26px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
  }
}
</style>
