<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in" :duration="500">
      <component :is="Component" />
    </transition>
  </router-view>
  <!-- Wait $route.name because doorman dont display route before API response -->
  <site-footer v-if="$route.name" />

  <modal></modal>

  <loading-screen data-cy="routeLoaded" :display="!$route.name"></loading-screen>
</template>

<script lang="ts" setup>
import { inject, watch } from "vue";
import { LocationQueryValue, useRoute, useRouter } from "vue-router";
import { ApiOpBackendEventName, useApiOp } from "@team-uep/vue-api-op";
import { MmTro } from "@team-uep/vue-mmtro-op";
import { useIubenda, IubendaEventName } from "@team-uep/vue-iubenda-op";

import { APISessionTokenResponse } from "@/types/api-extended.interfaces";
import { useTrackingStore } from "@/store/tracking";
import initPersistedState from "@/store/initPersistedState";
import initMmtro from "@/composables/initMmtro";

import SiteFooter from "@/components/SiteFooter.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Modal from "@/components/modals/Modal.vue";
import { getCurrentDecli } from "./helpers/declis";
import useUI from "./composables/useUI";

const iubenda = useIubenda();
const mmtro = inject<MmTro>("mmTro");

const route = useRoute();
const router = useRouter();
const api = useApiOp();
const trackingStore = useTrackingStore();
const ui = useUI();

// Store data in session storage
initPersistedState("opState");

// Automatically send mmtro tag
initMmtro();

watch(
  () => route.name,
  (newRoute, oldRoute) => {
    window.scrollTo(0, 0);

    // Init iubenda only first time
    // wait route to calculate current decli
    if (newRoute && !oldRoute) {
      const currentDecli = getCurrentDecli(route);
      if (iubenda) {
        iubenda.initialize({
          csConfiguration: currentDecli.iubendaCsConfiguration,
          buttonColor: ui.colors.primary,
        });
      }
    }
  }
);

// Clean URL by removing query params (after storing) exept modal query
// Update it only on init or if query changed
watch(
  () => route.query,
  (oldQuery) => {
    const nextQuery: { modal?: string | LocationQueryValue[] } = {};
    if (route.query.modal) {
      nextQuery.modal = route.query.modal;
    }
    // Replace route only if query added (or modal changed)
    if (JSON.stringify(oldQuery) !== JSON.stringify(nextQuery)) {
      router.replace({ query: nextQuery });
    }
  },
  { immediate: true }
);

// Iubenda
if (iubenda) {
  iubenda.on(IubendaEventName.CONSENT_GIVEN_MMTRO, () => {
    iubenda.mmtroConsent = true;
    if (mmtro) {
      mmtro.sendRtg();
    }
    return Promise.resolve();
  });

  iubenda.on(IubendaEventName.CONSENT_REFUSED_MMTRO, () => {
    iubenda.mmtroConsent = false;
    return Promise.resolve();
  });
}

// When loading the page, a session-token is generated.
// Store session-token tracking data to tracking store.
api.on(ApiOpBackendEventName.SESSION_TOKEN, (response) => {
  const data = response.data as APISessionTokenResponse;

  trackingStore.$patch({
    idvisit: data.data[0].visitId,
    mmtro: {
      tagid: data.data[0].oTagInfo.tagid || "6578716-48e850f7a8468d584517527b92eaf8c0",
    },
  });

  return Promise.resolve(response);
});

api.on(ApiOpBackendEventName.REGISTERED, (response) => {
  mmtro?.conversion();

  return Promise.resolve(response);
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
</style>
