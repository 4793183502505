<template>
  <div class="pt-4">
    <header>
      <h3 class="text-xl text-primary">{{ $t("modals.summary.title") }}</h3>
    </header>
    <ul class="list-friends list-unstyled">
      <li
        v-for="(friend, key) in friends"
        :key="key"
        class="friends-list-li row flex flex-wrap items-center justify-between"
      >
        <span class="table">{{ friend.email }}</span>
        <span class="m-auto mx-auto table pt-2 pl-4 sm:mx-0">
          <n-cta
            :data-cy="'friend-mail-' + key"
            type="a"
            :href="friend.mailto"
            :disabled="friend.subscribed"
            :border-radius="ui.panoply.cta.rounded ? '30px' : '0px'"
            :background-color="ui.colors.primary"
            target="_blank"
            class="actions"
          >
            {{ friend.subscribed ? $t("modals.summary.registered") : $t("modals.summary.relance") }}
          </n-cta>
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useApiOp } from "@team-uep/vue-api-op";
import { useI18n } from "vue-i18n";
import NCta from "@team-uep/n-cta";
import useUI from "@/composables/useUI";
// import NCopy from "@team-uep/n-copy";

type Friend = {
  email: string;
  mailto: string;
  subscribed: boolean;
};

const ui = useUI();

const friends = ref<Friend[]>([]);
const apiOp = useApiOp();
const { t } = useI18n();
apiOp.diffusionSummaryViral().then(({ data }) => {
  // eslint-disable-next-line camelcase
  friends.value = (data.data as { Email: string; id_UE_Envoi: string }[]).map((friend) => {
    const refVars = {
      websiteUrl: window.location.origin,
      FriendEmail: friend.Email,
      id_UE_Envoi: friend.id_UE_Envoi,
    };

    return {
      email: friend.Email,
      mailto: `mailto:${friend.Email}?subject=${encodeURIComponent(
        t("diffusionPage_summary_relance_subject")
      )}&body=${encodeURIComponent(t("diffusionPage_summary_relance_body", refVars))}`,
      subscribed: false,
    };
  });
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
