import { watch } from "vue";
import pinia from "./index";

/**
 * On component start, copy all the store state to the session storage.
 * Copy all store state to session storage of each change.
 * This method need to be called in the setup of the Main component
 * because the watcher is cleared up when the wrapping component is unmounted.
 */
export default (sessionKey: string): void => {
  if (!window.sessionStorage) {
    throw new Error("Session storage not supported");
  }

  const oldStateRaw = window.sessionStorage.getItem(sessionKey);

  if (oldStateRaw) {
    try {
      const oldState = JSON.parse(oldStateRaw) as Record<
        string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Record<string | number | symbol, any>
      >;
      pinia.state.value.tracking = oldState;
    } catch (error) {
      console.error(`Session storage corrupted: ${(error as Error).message}`);
    }
  }

  watch(
    pinia.state,
    (state) => {
      try {
        window.sessionStorage.setItem(sessionKey, JSON.stringify(state.tracking));
      } catch (error) {
        console.error(`Can not write on session storage: ${(error as Error).message}`);
      }
    },
    { deep: true }
  );
};
