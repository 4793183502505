<template>
  <PageContainer>
    <!-- Mobile only promo code. -->
    <p class="mb-4 mt-8 text-center text-[21px] font-[600] text-white lg:hidden">
      {{ $t("diffusion.promoLabel") }}
    </p>

    <p
      class="mx-auto mb-8 inline-block rounded-[24px] bg-white px-4 pb-2 pt-3 text-center text-[21px] font-[600] leading-none text-primary lg:hidden"
    >
      {{ user.$state.codeLot }}
    </p>

    <p
      class="mb-12 text-center text-[16px] font-[700] uppercase text-white lg:mb-0 lg:text-[24px]"
      v-html="$t('diffusion.subtitle')"
    ></p>

    <!-- Desktop only separator. -->
    <div class="my-12 mx-auto hidden h-[4px] w-[50px] bg-primary lg:block"></div>

    <DiffusionProductsCarousel class="mb-12" />

    <button
      type="button"
      class="mb-8 w-[200px] rounded-[24px] bg-primary py-4 text-center text-[14px] font-[700] uppercase leading-none text-white"
      @click="exitUrl(1)"
    >
      {{ $t("diffusion.redirection") }}
    </button>

    <p
      class="mb-6 text-center text-[16px] font-[700] leading-tight text-white lg:text-[21px]"
      v-html="$t('diffusion.share.title')"
    ></p>

    <div class="mb-16 flex items-center justify-center space-x-8">
      <button
        v-for="(share, index) in shares"
        :key="`share-${share}`"
        type="button"
        @click="exitUrl(index + 2)"
      >
        <img
          class="block h-[48px] w-[48px]"
          :src="require(`@/assets/diffusion/${share}.svg`)"
          :alt="share"
        />
      </button>
    </div>
  </PageContainer>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user";
/* eslint import/no-cycle: "off" */
import { useExitUrl } from "@/composables/useShares";

import PageContainer from "@/components/PageContainer.vue";
import DiffusionProductsCarousel from "@/components/diffusion/DiffusionProductsCarousel.vue";

const exitUrl = useExitUrl();
const user = useUserStore();

const shares = ["facebook", "instagram", "youtube"];
</script>

<style lang="scss" scoped></style>
