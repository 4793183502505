<template>
  <div class="relative min-h-screen bg-[#232120] lg:flex lg:items-stretch lg:justify-center">
    <div
      class="image-container relative flex w-full flex-col items-center pb-8 lg:min-h-screen lg:justify-center lg:pt-12"
    >
      <LogoKrups class="absolute top-0 z-10" />

      <!-- Backdrop overlay above image. -->
      <div
        class="pointer-events-none absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75"
      ></div>

      <p
        class="relative mt-16 mb-4 text-center text-[24px] font-[700] uppercase text-white lg:mb-8 lg:mt-0 lg:text-[40px] lg:leading-tight"
        v-html="$t('diffusion.title')"
      ></p>

      <img
        class="relative mx-auto mb-4 block h-[140px] w-auto lg:mb-8 lg:h-[210px]"
        src="@/assets/diffusion/coupon.png"
        alt="30% de réduction"
      />

      <p
        class="relative text-center text-[14px] font-[600] text-white lg:mb-16 lg:text-[16px]"
        v-html="$t('diffusion.promoDescription')"
      ></p>

      <!-- Desktop promo-code. -->
      <p
        class="relative mb-4 hidden text-center text-[21px] font-[600] leading-tight text-white lg:block"
        v-html="$t('diffusion.promoLabel')"
      ></p>

      <p
        class="relative hidden rounded-[24px] bg-white px-6 pb-3 pt-4 text-[21px] font-[600] uppercase leading-none text-primary lg:block"
      >
        {{ user.$state.codeLot }}
      </p>

      <!-- Mobile bottom separation bar. -->
      <div class="absolute bottom-0 left-0 right-0 h-[4px] w-full bg-primary lg:hidden"></div>

      <!-- Desktop right separation bar. -->
      <div class="absolute right-0 top-0 bottom-0 hidden w-[4px] bg-primary lg:block"></div>
    </div>

    <section class="flex flex-col py-4 lg:min-w-[580px] lg:self-center lg:py-0">
      <div class="mx-auto w-full text-center lg:max-w-[580px]">
        <slot></slot>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user";

import LogoKrups from "@/components/ui/LogoKrups.vue";

const user = useUserStore();
</script>

<style lang="scss" scoped>
.image-container {
  background-image: url("@/assets/register/mobile-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
