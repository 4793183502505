import { defineStore } from "pinia";

import { UserState } from "@/types/store.interfaces";

export const userStore = {
  id: "user" as const,

  state: (): UserState =>
    ({
      id: null,
      firstname: null,
      lastname: null,
      email: null,
      phonenumber: null,
      civility: null,
      birthdate: null,
      responses: {
        question4: null,
        question5: null,
      },
      // Optin guide on Handbook: page /doc/les-optins-W43mNQK51u
      optins: {
        // iOptin_1 from back-end.
        emailMobileOp: null,
        // iOptin_2 from back-end.
        emailBrand: null,
        // iOptin_3 from back-end.
        emailPartners: null,
        // iOptin_4 from back-end.
        mobileBrand: null,
      },
    } as UserState),

  getters: {},

  actions: {},
};

export const useUserStore = defineStore<"user", UserState>(userStore);
