<template>
  <footer class="w-full bg-[#232120]">
    <div class="flex flex-row flex-wrap items-center justify-center py-4 text-[14px] text-white">
      <!-- Terms -->
      <button
        v-if="$te('footer.terms')"
        data-cy="terms-open-cta"
        class="cursor-pointer"
        @click="openModal('Terms')"
      >
        {{ $t("footer.terms") }}
      </button>

      <span v-if="$te('footer.terms')" class="px-2">|</span>

      <!-- Iubenda cookies preferences -->
      <button
        v-if="$te('footer.cookiesPreferences')"
        class="iubenda-cs-preferences-link cursor-pointer"
        @click="iubenda.openPreferencesModal()"
      >
        {{ $t("footer.cookiesPreferences") }}
      </button>

      <span v-if="$te('footer.cookiesPreferences')" class="px-2">|</span>

      <!-- Iubenda cookies -->
      <button
        v-if="$te('footer.cookies')"
        class="cursor-pointer"
        data-cy="cookies-open-cta"
        @click="openModal('IubendaPrivacyPolicy')"
      >
        {{ $t("footer.cookies") }}
      </button>

      <span v-if="$te('footer.cookies')" class="px-2">|</span>

      <!-- Contact us mailto -->
      <a
        v-if="$te('footer.contact.button')"
        data-cy="contact-cta"
        :href="`mailto:${$t('footer.contact.email')}?subject=${$t('footer.contact.subject')}`"
        target="_blank"
        class="cursor-pointer"
      >
        {{ $t("footer.contact.button") }}
      </a>

      <!-- Contact modal FAQ -->
      <!-- <button
        v-if="$te('footer.contact.button')"
        type="button"
        data-cy="modal-contact-cta"
        class="cursor-pointer"
        @click="openModal('Contact')"
      >
        {{ $t("footer.contact.button") }}
      </button> -->

      <span v-if="$te('footer.contact.button')" class="px-2">|</span>

      <!-- Facebook terms
      <button
        v-if="$te('footer.charteFacebook')"
        data-cy="privacyPolicy-cta"
        class="cursor-pointer"
        @click="openModal('CharteFb')"
      >
        {{ $t("footer.charteFacebook") }}
      </button>

      <span v-if="$te('footer.charteFacebook')" class="px-2">|</span> -->

      <!-- Realisation modal -->
      <button
        v-if="$te('footer.realisation.button')"
        data-cy="realisation-cta"
        class="cursor-pointer"
        @click="openModal('Realisation')"
      >
        {{ $t("footer.realisation.button") }}
      </button>
    </div>

    <div v-if="$t('footer.mentions')" class="mx-auto max-w-5xl pb-8 text-white">
      <p class="px-4 text-center text-sm" v-html="$t('footer.mentions')"></p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";
import useModal from "@/composables/useModal";

const iubenda = inject("iubenda") as Iubenda;
const { openModal } = useModal();
</script>
